import styles from './VTO.module.scss';

type VTOButtonProps = {
	clickEvent: () => void;
	text: string;
	children?: React.ReactNode;
};

const VTOButton = ({ clickEvent, text, children }: VTOButtonProps) => {
	return (
		<button
			type='button'
			className={styles['vto-icon']}
			tabIndex={0}
			onKeyDown={() => {
				clickEvent();
			}}
			onClick={() => {
				clickEvent();
			}}
		>
			{children}
			<p className={styles['vto-icon__text']}>{text}</p>
		</button>
	);
};

export default VTOButton;
