import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Refresh = ({ color = 'white' }) => {
	return (
		<AccessibleIcon.Root label='refresh'>
			<svg height='40' viewBox='0 0 96 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M46.75 7.91666L42.5833 11.6667L46.75 15.4167'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M49.25 32.0833L53.4167 28.3333L49.25 24.5833'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M44.25 11.6667H50.0833C55.6062 11.6667 60.0833 16.1438 60.0833 21.6667V22.0833'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M51.75 28.3333H45.9167C40.3938 28.3333 35.9167 23.8562 35.9167 18.3333V17.9167'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Refresh;
