const TakePhoto = ({ color = 'white' }) => {
	return (
		<svg height='40' viewBox='0 0 96 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M58 13H38C36.3431 13 35 14.3431 35 16V28C35 29.6569 36.3431 31 38 31H58C59.6569 31 61 29.6569 61 28V16C61 14.3431 59.6569 13 58 13Z'
				stroke={color}
				strokeWidth='2'
			/>
			<path d='M41 9H45C45.5523 9 46 9.44772 46 10V13H40V10C40 9.44772 40.4477 9 41 9Z' stroke={color} strokeWidth='2' />
			<path
				d='M53 22C53 19.2386 50.7614 17 48 17C45.2386 17 43 19.2386 43 22C43 24.7614 45.2386 27 48 27C50.7614 27 53 24.7614 53 22Z'
				stroke={color}
				strokeWidth='2'
			/>
		</svg>
	);
};

export default TakePhoto;
